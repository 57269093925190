<template>
    <div class="scrolllayout"  @click="handleswiperchoose">
        <diybar :diybarData="diybarData" @handlediybar="handlediybar"></diybar>
        <div :class="swiper1[0].swiperclass==0?'noswiper':'swiper'" :style="'z-index:'+swiper1[0].indexz" >
            <!--                    背景图-->

            <img class="bac"
                 :src="swiper1[0].bac" alt="">
            <!--                    遮罩以上-->
            <div class="shadow">
                <div class="shadow-lean">
                    <div class="shadow-lean-item"></div>
                    <div class="shadow-lean-item"></div>
                    <div class="shadow-lean-item"></div>
                    <div class="shadow-lean-item"></div>
                </div>
                <div class="shadow-swiper0-top">
                    <div class="shadow-swiper0-top-top">
                        <div class="shadow-swiper0-top-top-item">鑫嵘科技·产品中心丨基于对市场及行业的理解</div>
                        <div class="shadow-swiper0-top-top-item">研发打造星物种产品宇宙系列</div>
                    </div>
                    <div class="shadow-swiper0-top-bottom">
                        <div class="shadow-swiper0-top-bottom-item">Xin Huang Science and Technology Products Department R & D</div>
                        <div class="shadow-swiper0-top-bottom-item">based on the understanding of the market and industry to create</div>
                        <div class="shadow-swiper0-top-bottom-item">star Species Product Universe series</div>
                    </div>
                </div>
                <div class="shadow-swiper0-center">
                    <div class="shadow-swiper0-center-top">
                        <div class="shadow-swiper0-center-top-item">产品宇宙包含了企服SaaS工具，互动工具</div>
                        <div class="shadow-swiper0-center-top-item">供需平台，私域搭建解决方案，品牌一体化视觉</div>
                        <div class="shadow-swiper0-center-top-item">展示系统等一系列软件及平台</div>
                    </div>
                    <div class="shadow-swiper0-center-bottom">
                        <div class="shadow-swiper0-center-bottom-item">Product Universe includes a series of software and platforms, such as Saas tool,</div>
                        <div class="shadow-swiper0-center-bottom-item">interactive tool supply and demand platform, private domain build solution,</div>
                        <div class="shadow-swiper0-center-bottom-item">integrated brand visual display system, etc.</div>
                    </div>
                </div>
                <div class="shadow-swiper0-bottom">
                    <div class="shadow-swiper0-bottom-top">引 领 未 来&nbsp;&nbsp;&nbsp;&nbsp;产 品 宇 宙</div>
                    <div class="shadow-swiper0-bottom-bottom"><span style="font-size: 52px">P</span>roduct <span style="font-size: 30px">U</span>niverse</div>
                </div>
            </div>
        </div>
        <div :class="swiper1[1].swiperclass==0?'noswiper':(swiper1[1].swiperclass==1?'reswiper':'swiper')" :style="'z-index:'+swiper1[1].indexz" >
            <!--                    背景图-->

            <img class="bac"
                 :src="swiper1[1].bac" alt="">
            <!--                    遮罩以上-->
            <div class="shadow" style="background: rgba(0,0,0,0.8)">
                <div class="shadow-bac">
                    <img class="bac" src="@/img/p5.png" alt="">
                </div>
                <div class="shadow-swiper1-top">
                    <div class="shadow-swiper1-top-top">On the scene now</div>
                    <div class="shadow-swiper1-top-center"><span class="wy" style="font-size: 28px;">即刻现场</span></div>
                    <div class="shadow-swiper1-top-bottom">Xin Huang Science and Technology Products Department R & D based on the understanding of the market and industry to create star Species Product Universe series</div>
                </div>
                <div class="shadow-swiper1-bottom">
                    <div class="shadow-swiper1-bottom-left">
                        <div class="shadow-swiper1-bottom-left-top">
                            <div class="shadow-swiper1-bottom-left-top-top">Instant show</div>
                            <div class="shadow-swiper1-bottom-left-top-bottom"><span class="wy" style="font-size: var(--s-t-size);">即刻 show</span></div>
                        </div>
                        <div class="shadow-swiper1-bottom-left-bottom">
                            <div class="shadow-swiper1-bottom-left-bottom-top" :style="componentData==1024?'width:500px':''">Xin Huang Science and Technology Products Department R & D based on the understanding of the market and industry to create star Species Product Universe series</div>
                            <a href="" class="shadow-swiper1-bottom-left-bottom-bottom" @click.stop>即刻体验</a>
                        </div>
                    </div>
                    <div class="shadow-swiper1-bottom-right">
                        <div class="shadow-swiper1-bottom-right-top"><span class="wy" style="font-size: 26px;">Administration</span></div>
                        <div class="shadow-swiper1-bottom-right-bottom" :style="componentData==1024?'width:370px':''">Xin Huang Science and Technology Products Department R & D based on the understanding of the market and industry to create star Species Product Universe series</div>
                    </div>
                </div>
            </div>
        </div>
        <div :class="swiper1[2].swiperclass==0?'noswiper':(swiper1[2].swiperclass==1?'reswiper':'swiper')" :style="'z-index:'+swiper1[2].indexz" >
            <!--                    背景图-->

            <img class="bac"
                 :src="swiper1[2].bac" alt="">
            <!--                    遮罩以上-->
            <div class="shadow" style="background: rgba(0,0,0,0.8)">
                <div class="shadow-bac">
                    <img class="bac" src="@/img/p6.png" alt="">
                </div>
                <div class="shadow-swiper1-top">
                    <div class="shadow-swiper1-top-top">Administration</div>
                    <div class="shadow-swiper1-top-center"><span class="wy" style="font-size: 28px">行政通</span></div>
                    <div class="shadow-swiper1-top-bottom">Xin Huang Science and Technology Products Department R & D based on the understanding of the market and industry to create star Species Product Universe series</div>
                </div>
                <div class="shadow-swiper1-bottom">
                    <div class="shadow-swiper1-bottom-left">
                        <div class="shadow-swiper1-bottom-left-top">
                            <div class="shadow-swiper1-bottom-left-top-top">Instant show</div>
                            <div class="shadow-swiper1-bottom-left-top-bottom"><span class="wy"  style="font-size: var(--s-t-size);">星福利</span></div>
                        </div>
                        <div class="shadow-swiper1-bottom-left-bottom">
                            <div class="shadow-swiper1-bottom-left-bottom-top" :style="componentData==1024?'width:500px':''">Xin Huang Science and Technology Products Department R & D based on the understanding of the market and industry to create star Species Product Universe series</div>
                            <a href="" class="shadow-swiper1-bottom-left-bottom-bottom" @click.stop>即刻体验</a>
                        </div>
                    </div>
                    <div class="shadow-swiper1-bottom-right">
                        <div class="shadow-swiper1-bottom-right-top"><span class="wy" style="font-size: 26px">Administration</span></div>
                        <div class="shadow-swiper1-bottom-right-bottom"  :style="componentData==1024?'width:370px':''">Xin Huang Science and Technology Products Department R & D based on the understanding of the market and industry to create star Species Product Universe series</div>
                    </div>
                </div>
            </div>
        </div>
        <div :class="swiper1[3].swiperclass==0?'noswiper':'reswiper'" :style="'z-index:'+swiper1[3].indexz" >
            <img class="bac"
                 :src="swiper1[3].bac" alt="">
            <!--                    遮罩以上-->
            <div class="shadow" style="background: rgba(0,0,0,0.8)">
                <div class="shadow-swiper1-top">
                    <div class="shadow-swiper1-top-top"><span class="wy" style="font-size: 30px">驱动产业数字化</span></div>
                    <div class="shadow-swiper1-top-center"><span class="wy" style="font-size: 30px">用创意打造城市星物种</span></div>
                    <div class="shadow-swiper1-top-bottom">Xin Huang Science and Technology Products Department R & D based on the understanding of the market and industry to create star Species Product Universe series</div>
                </div>
                <div class="shadow-swiper2-bottom">
                    <div class="shadow-swiper2-bottom-left">
                        <div class="shadow-swiper2-bottom-left-top" @click.stop>
                            <span class="wy" style="font-size: var(--b-c-size);">更多产品</span>
                        </div>
                        <div class="table">
                            <div class="table-columns" v-for="(c,i) in columns" :key="c.key" :style="componentData==1024&&i==0?'width:107px':''">
                                <div class="table-columns-top">
                                    <div class="table-columns-title">{{c.text}}</div>
                                    <div class="table-columns-icon" @click.stop v-if="c.key =='function'">
                                        <img src="@/img/p8.png" alt="">
                                    </div>
                                </div>
                                <div class="table-columns-bottom">
                                    <div class="table-columns-item" v-for="v in datalist" :key="v.id">
                                        {{v[c.key]}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="shadow-swiper2-bottom-right">
                        <div class="shadow-swiper2-bottom-right-top">
                            <img src="@/img/p7.png" alt="">
                        </div>
                        <div class="shadow-swiper2-bottom-right-bottom">
                            <div class="shadow-swiper2-bottom-right-bottom-top">欢迎进入星物种产品宇宙</div>
                            <div class="shadow-swiper2-bottom-right-bottom-center">提交需求 我们会与您联系</div>
                            <div class="shadow-swiper2-bottom-right-bottom-bottom"><a href="" class="shadow-swiper2-bottom-right-bottom-bottom-text" @click.stop>需求提交</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    </div>
</template>

<script>
    import s1 from '../img/p1.jpg'
    import s2 from '../img/p2.jpg'
    import s3 from '../img/p3.jpg'
    import s4 from '../img/p4.jpg'

    //白色进度条
    import diybar from "../components/diybar";

    export default {
        data(){
            return{
                //轮播到哪一页，0为第一页，1为第二页，2为第三页，3为第四页，-1为跳转上一页路由，4为跳转下一页路由
                swiperchoose: 0,
                //记录变化前的swiperchoose
                oddswiperchoose:0,


                //界面宽度的一半
                resizeX:0,


                //节流，阻止事件
                preventcontact:0,


                //轮播
                swiper1: [
                    {
                        //背景图
                        bac: s1,
                        //高度
                        indexz:99,
                        //判断左移还是右移的class,1为左移，-1为右移，0为进入页面不动的默认情况
                        swiperclass:0,
                        diybarposition:1,
                    },
                    {
                        bac: s2,
                        indexz:-1,
                        //判断左移还是右移的class
                        swiperclass:0,
                        diybarposition:1,
                    },
                    {
                        bac: s3,
                        indexz:-1,
                        //判断左移还是右移的class
                        swiperclass:0,
                        diybarposition:1,
                    },
                    {
                        bac: s4,
                        indexz:1,
                        //判断左移还是右移的class
                        swiperclass:0,
                        diybarposition:1,
                    }
                ],
                //diybar配置
                diybarData:{
                    //节点数量
                    count:4,
                    //目标节点位置
                    targetleft:35,
                    beforedetroy : false,
                    position:1,
                  target:0,
                },
                columns:[
                    {
                        key:'logo',
                        text:'LOGO',
                    },
                    {
                        key:'product',
                        text:'产品名称',
                    },
                    {
                        key:'function',
                        text:'功能',
                    }
                ],
                datalist:[
                    {
                        logo:'LOGO',
                        product:'产品名称',
                        function:'功能',
                        id:1,
                    },
                    {
                        logo:'LOGO',
                        product:'产品名称',
                        function:'功能',
                        id:2,
                    },
                    {
                        logo:'LOGO',
                        product:'产品名称',
                        function:'功能',
                        id:3,
                    },
                    {
                        logo:'LOGO',
                        product:'产品名称',
                        function:'功能',
                        id:4,
                    },
                ],
            }
        },
        components:{
            diybar,
        },
        props:{
            componentData:{
                default:0,
                type:Number,
            }
        },
        created() {
            //监听鼠标滚动，500毫秒目的是为了等上一次滚动结束
            setTimeout(()=>{
                window.addEventListener('mousewheel',this.handlemouse)
            },500)
            //监听网页宽度
            window.addEventListener('resize',this.resize)
            this.resize()

            //监听按键
            setTimeout(()=>{
                    window.addEventListener('keydown',this.keydown)
            },500)
        },
        beforeUnmount() {
            this.diybarData.beforedetroy = false
        },
        methods: {
            //点击鼠标时触发,点击左边界面右移下一页，点击右边界面左移上一页
            handleswiperchoose(e) {
              //监听是否全屏
              let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement
              if(!isFullScreen){
                return;
              }
                if(this.preventcontact){
                    return
                }
                this.preventcontact=1
                let X = e.clientX
                // if(this.componentData){
                //     X = e.clientY
                // }
                if(X>=this.resizeX){
                    this.swiperchoose++
                }else{
                    this.swiperchoose--
                }
                setTimeout(()=>{
                    this.preventcontact=0
                },500)
            },
            handlediybar(e){
                if(e.target==this.swiperchoose){
                    return
                }
                this.swiperchoose=e.target
                this.diybarData.target = e.target
            },
            //监听鼠标滚动事件,上滚回退上一张，下滚继续下一张
            handlemouse(e){

                if(this.preventcontact){
                    return;
                }
                this.preventcontact=1
                if(e.deltaY>0){
                    this.swiperchoose++

                }else{

                    this.swiperchoose--


                }

                setTimeout(()=>{
                    this.preventcontact=0
                },500)
            },
            resize(){
                if(this.componentData){
                    this.resizeX = this.componentData/2
                    return
                }
                this.resizeX = document.body.clientWidth/2
            },
            //跳转路由
            handlerouter(e){
                if(window.location.pathname==e){
                    return
                }
                this.$router.push(e)
            },
            //监听键盘
            keydown(e){
                if(this.preventcontact){
                    return;
                }
                this.preventcontact=1
                if(e.keyCode==39){
                    this.swiperchoose++
                }else if(e.keyCode==37){
                    this.swiperchoose--
                }else if(e.keyCode==38){
                    this.swiperchoose=-1
                }else if(e.keyCode==40){
                    this.swiperchoose=4
                }
                setTimeout(()=>{
                    this.preventcontact=0
                },500)
            }
        },

        watch:{
            swiperchoose:function (e) {
                if(e>3){
                    this.handlerouter(this.$store.state.routerlist[this.$route.meta.index+1].router)
                    return
                }else if(e<0){
                    this.handlerouter(this.$store.state.routerlist[this.$route.meta.index-1].router)
                    return;
                }
                if(e==0){
                    this.swiper1[0].indexz=99
                    this.swiper1[2].indexz=-1
                    this.swiper1[3].indexz=-1
                    this.swiper1[1].indexz=-1
                    if(this.oddswiperchoose>=1){
                        this.swiper1[0].swiperclass= -1
                    }
                    this.swiper1[this.oddswiperchoose].indexz=1
                    this.swiper1[1].swiperclass= 0
                    this.swiper1[2].swiperclass= 0
                    this.swiper1[3].swiperclass= 0



                }else if(e==1){
                    this.swiper1[1].indexz=99
                    this.swiper1[3].indexz=-1
                    this.swiper1[0].indexz=-1
                    this.swiper1[2].indexz=-1
                    if(this.oddswiperchoose>=2){
                        this.swiper1[1].swiperclass= -1
                    }else{
                        this.swiper1[1].swiperclass= 1
                    }
                    this.swiper1[this.oddswiperchoose].indexz=1
                    this.swiper1[0].swiperclass= 0
                    this.swiper1[2].swiperclass= 0
                    this.swiper1[3].swiperclass= 0
                }else if(e==2){
                    this.swiper1[0].indexz=-1
                    this.swiper1[2].indexz=99
                    this.swiper1[1].indexz=-1
                    this.swiper1[3].indexz=-1
                    if(this.oddswiperchoose>=3){
                        this.swiper1[2].swiperclass= -1

                    }else{
                        this.swiper1[2].swiperclass= 1
                    }
                    this.swiper1[this.oddswiperchoose].indexz=1
                    this.swiper1[0].swiperclass= 0
                    this.swiper1[1].swiperclass= 0
                    this.swiper1[3].swiperclass= 0
                }else if(e==3){
                    this.swiper1[0].indexz=-1
                    this.swiper1[1].indexz=-1
                    this.swiper1[3].indexz=99
                    this.swiper1[2].indexz=-1
                    if(this.oddswiperchoose<=2){
                        this.swiper1[3].swiperclass= 1
                    }
                    this.swiper1[this.oddswiperchoose].indexz=1
                    this.swiper1[0].swiperclass= 0
                    this.swiper1[1].swiperclass= 0
                    this.swiper1[2].swiperclass= 0
                }
                this.oddswiperchoose = e
                this.diybarData.target= e
                this.diybarData.position = this.swiper1[e].diybarposition
            }
        },
    }
</script>

<style lang="scss" scoped>

    .swiper{
        position: absolute;
        left: -100%;
        width: 100%;
        height: 100%;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
        animation-name: turnright;
    }
    @keyframes turnright {
        0% {
            left: -100%;
        }
        100% {
            left: 0;
        }
    }
    .reswiper{
        position: absolute;
        left: 100%;
        width: 100%;
        height: 100%;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
        animation-name: turnleft;
    }
    @keyframes turnleft {
        0% {
            left: 100%;
        }
        100% {
            left: 0;
        }
    }

    .noswiper{
        position: absolute;
        left:0%;
        width: 100%;
        height: 100%;
    }

    .swiper-unit-home{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: var(--b-t-size);
        width: 675px;
        transform: translateY(-50%);
        margin-left: 4%;
    }
    .swiper-home{
        position: absolute;
        left: 2%;
        right: 0;
        top: 50%;
    }
    .swiper-unit-right-unit-home{
        display: block;
        background: linear-gradient(to left, var(--s-color1), #f2e6cd 100%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .swiper-unit-right-unit-home:first-of-type{
        display: inline-block;
        background: linear-gradient(to left, var(--s-color1), #f2e6cd 100%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .bottom-home{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 16%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
    }
    .bottom-right-home{
        display: flex;
        flex-direction: row;
        width: 428px;
        font-size: var(--s-c-size);
        color: rgba(255,255,255,0.9);
        margin-right: 8%;
    }
    .bottom-right-left-home{
        background: rgba(255,255,255,0.9);
        width: 1.5px;
        height: 40px;
        margin-right: 34px;
        margin-top: 7px;
    }
    .swiper1-home{
        line-height: 2;
        position: relative;
        width: 100%;
    }
    .swiper1-unit-home{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
    .shadow-top{
        position: absolute;
        top:22% ;
        left: 5%;
        @media screen and (max-width: 1650px) {
            top:15%
        }
    }
    .shadow-top{
        display: flex;
        flex-direction: column;
    }
    .shadow-top-top-item-main{
        font-size: 25px;
        background: linear-gradient(to left, var(--s-color1), #f2e6cd 100%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .shadow-top-bottom{
        font-size: var(--s-c-size);
        width: 500px;
        margin-top: 23px;
        line-height:1.9;
    }
    .shadow-center{
        position: absolute;
        top:43%;
        left: 5%;
        right: 8.7%;
        @media screen and (max-width: 1650px) {
            top:36%
        }
    }
    .shadow-center-top{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }
    .shadow-center-top-item{
        display: flex;
        flex-direction: column;
        font-size: 13px;
    }
    .shadow-center-top-item:first-of-type{
        margin-left: 0;
    }
    .shadow-center-top-item-top{
        width: 205px;
        height: 126px;
        opacity: 0.6;
        margin-bottom: 20px;




        @media screen and (max-width: 1650px) {
            height: 100px;
            width: 163px;
        }
    }
    .shadow{
        background: rgba(0, 0, 0, 0.5);
    }
    .shadow-center-top-item-bottom{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .shadow-center-top-item-bottom-left{
        margin-left: 13px;
    }
    .shadow-center-top-item-bottom-right{
        width: 27px;
        height: 29px;
    }
    .shadow-center-bottom{
        width: 100%;
        height: 12px;
        background: linear-gradient(to right,#092d46,#061622);
        margin-top: 29px;
    }
    .shadow-bottom{
        position: absolute;
        left: 5%;
        top:72%;
        right:8.7%;
        @media screen and (max-width: 1650px) {
            top: 65%;
        }
    }
    .shadow-bottom-top-top{
        font-size: var(--s-t-size);
        display: inline-block;
        background: linear-gradient(to left, var(--s-color1), #f2e6cd 100%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .shadow-bottom-top-bottom{
        font-size: var(--s-c-size);
        width: 500px;
        line-height: 1.9;
        margin-top: 8px;
    }
    .shadow-bottom-bottom{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 39px;
        flex-wrap: wrap;
        justify-content: space-between;
        @media screen and (max-width: 1400px) {
            justify-content: flex-start;
        }
    }
    .shadow-bottom-bottom-item{
        display: flex;
        flex-direction: row;
        margin-top: 10px;
    }
    .shadow-bottom-bottom-item-left{
        flex: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        background: linear-gradient(to right,#015794,#082439);
        padding-top: 12px;
        padding-bottom:18px ;
        padding-left: 14px;
        padding-right: 28px;
    }
    .shadow-bottom-bottom-item-left-left{
        width: 23px;
        height: 23px;
    }
    .shadow-bottom-bottom-item-left-right{
        margin-left: 16px;
        font-size: var(--s-c-size);
    }
    .shadow-bottom-bottom-item-right{
        width: 26px;
        height: 25px;
        margin-top: 10px ;
        margin-left: 19px;
        margin-right: 19px;
    }
    .shadow-center-top-item1{
        height: 195px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .shadow-center-top-item1-bottom{
        font-size: var(--s-c-size);
        width: 181.5px;
        line-height: 1.5;
    }
    .shadow-center-top-item1-center{
        font-size: var(--s-t-size);
        background: linear-gradient(to right,#0173c6 30%,#034677 );
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        flex: none;
        position: absolute;
        top: 59px;
    }
    .shadow-bottom-bottom-item1{
        width: 403px;
        height: 83px;
        min-width: 360px;
        position: relative;
        margin-left: 10px;
    }
    .shadow-bottom-bottom-item1:first-of-type{
        margin-left: 0;
    }
    .shadow-bottom-bottom-item1-shadow {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        padding-top: 18px;
        padding-bottom: 9px;
        justify-content: space-between;
        padding-left: 25px;
        padding-right: 17px;
        background-image: linear-gradient(to right,rgba(#085ca4,0.9),rgba(#11365d,0.5) 80%);
    }
    .shadow-bottom-bottom-item1-shadow-top{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .shadow-bottom-bottom-item1-shadow-top-left-top{
        font-size: 16px;
    }
    .shadow-bottom-bottom-item1-shadow-top-left-bottom{
        font-size: 11px;
    }
    .shadow-bottom-bottom-item1-shadow-top-right{
        width: 34px;
        height: 33px;
    }
    .shadow-bottom-bottom-item1-shadow-bottom{
        font-size: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    .shadow-center-top-item3{
        width: 297px;
        height: 104px;
    }
    .shadow-center-top-item3:nth-child(1){
        background-image: linear-gradient(to right,rgba(#031927,0.8),rgba(#034474,0.9));
    }
    .shadow-center-top-item3:nth-child(2){
        background-image: linear-gradient(to right,rgba(#665627,0.7),rgba(#3c3013,0.7));
    }
    .shadow-center-top-item3:nth-child(3){
        background-image: linear-gradient(to right,rgba(#0078ce,0.9),rgba(#050f18,0.5));
    }
    .shadow-lean{
        position: absolute;
        /*left: 36.3%;*/
        left: -30%;
        top: -50%;
        bottom: 0;
        right: -50%;
        display: flex;
        flex-direction: row;
        /*justify-content: space-between;*/
    }
    .shadow-lean-item{
        background: rgba(0,0,0,0.7);
        height: 140%;
        transform: rotateZ(26deg);
        width:11px;
    }
    .shadow-lean-item:nth-child(1){
        width: 34%;
    }
    .shadow-lean-item:nth-child(2){
        margin-left: 320px;
    }
    .shadow-lean-item:nth-child(3){
        margin-left: 270px;
    }
    .shadow-lean-item:nth-child(4){
        width:30%;
        margin-left: 315px;
    }
    .shadow-lean-item:first-of-type{
        margin-left: 0;
    }
    .shadow-swiper0-top{
        position: absolute;
        top: 40.4%;
        right: 19%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: right;
    }
    .shadow-swiper0-center{
        position: absolute;
        top: 60.4%;
        left: 3.8%;
        display: flex;
        flex-direction: column;
    }
    .shadow-swiper0-top-top{
        font-size: 30px;
        margin-bottom: 17px;
    }
    .shadow-swiper0-center-top{
        font-size: 15px;
    }
    .shadow-swiper0-top-bottom{
        font-size:var(--b-c-size);
    }
    .shadow-swiper0-center-bottom{
        font-size:12px;
    }
    .shadow-swiper0-bottom {
        position: absolute;
        left: 50%;
        width: 233px;
        height: 71px;
        bottom: 10%;
        transform: translateX(-50%);
    }
    .shadow-swiper0-bottom-top{
        font-size: 15px;
        position: absolute;
        top:15px;
        left: 36px;
    }
    .shadow-swiper0-bottom-bottom{
        font-size:23px;
        position: absolute;
        bottom: 0px;
        left: 1px;
        background: linear-gradient(to right,rgba(#044e86,1) 40%,rgba(#052846,1));
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: bold;
    }
    .shadow-bac{
        position: absolute;
        right: -5%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 58%;
    }
    .shadow-swiper1-top{
        position: absolute;
        top: 20.2%;
        left: 5%;
        height: 175px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .shadow-swiper1-top-bottom{
        width: 620px;
        font-size: var(--b-c-size);
    }
    .shadow-swiper1-top-center{
        position: absolute;
        left: 0;
        top: 50px;
    }
    .shadow-swiper1-top-top{
        font-size: 30px;
    }
    .shadow-swiper1-bottom{
        background-image: linear-gradient(to right,rgba(#09132b,0.6) 50%,rgba(#11151c,0.2));
        position: absolute;
        top: 48.7%;
        left: 4.2%;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
    }
    .shadow-swiper1-bottom-left{
        padding-left: 15px;
        padding-right: 16px;
        padding-bottom: 43px;
        padding-top: 50px;
    }
    .shadow-swiper1-bottom-right{
        padding-bottom: 39px;
        padding-right: 15px;
    }
    .shadow-swiper1-bottom-left-top,.shadow-swiper1-bottom-left-bottom{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: right;
    }
    .shadow-swiper1-bottom-left-top{
        font-size: var(--s-t-size);
        margin-bottom: 20px;
    }

    .shadow-swiper1-bottom-left-bottom-top{
        font-size: var(--b-c-size);
        width: 540px;
    }
    .shadow-swiper1-bottom-left-bottom-bottom{
        font-size: var(--s-c-size);
        border: 2px solid white;
        padding: 3px 32px 4px 32px;
        margin-top: 22px;
    }

    .shadow-swiper1-bottom-right-bottom{
        font-size: 16px;
        margin-top: 13px;
        width: 420px;
    }

    .shadow-swiper2-bottom{
        position: absolute;
        top: 47.8%;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .shadow-swiper2-bottom-left{
        margin-left: 5.2%;
        display: flex;
        flex-direction: column;
        margin-right: 30px;
    }
    .shadow-swiper2-bottom-left-top{
        font-size: var(--b-c-size);
        margin-bottom:25px;
        cursor: pointer;
    }

    .shadow-swiper2-bottom-right{
        margin-right: 13.6%;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 308px;
    }
    .shadow-swiper2-bottom-right-top{
        width: 79px;
        height: 79px;
        margin-bottom: 29px;
    }
    .shadow-swiper2-bottom-right-bottom{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .shadow-swiper2-bottom-right-bottom-top{
        font-size: 28px;
    }
    .shadow-swiper2-bottom-right-bottom-center{
        font-size: 11px;
        margin-top: 11px;
        margin-bottom: 34px;
    }
    .shadow-swiper2-bottom-right-bottom-bottom{
        font-size: var(--s-c-size);
    }
    .shadow-swiper2-bottom-right-bottom-bottom-text{
        padding: 4px 42px 6px;
        background-image: linear-gradient(to right,#0662a4,#033458);
    }
</style>
